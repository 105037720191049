import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ExeKGuides: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page exilium-page'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_guides.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium Guides</h1>
          <h2>
            Best guides for Girls' Frontline 2: Exilium that will help you
            understand the game better
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Newbie Zone" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/gfl-exilium/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Beginner Guide"
          link="/gfl-exilium/guides/beginner-guide"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_beg.webp"
              alt="Beginner Guide"
            />
          }
        />
        <CategoryCard
          title="Reroll Guide"
          link="/gfl-exilium/guides/reroll-guide"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_reroll.webp"
              alt="Reroll Guide"
            />
          }
        />
      </Row>
      <SectionHeader title="Generic Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Attachments Farming"
          link="/gfl-exilium/guides/attachment-farming"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_atta.webp"
              alt="Attachments Farming"
            />
          }
        />
        <CategoryCard
          title="Affinity & Dispatch"
          link="/gfl-exilium/guides/affinity-and-dispatch"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_affinity.webp"
              alt="Affinity & Dispatch"
            />
          }
        />
        <CategoryCard
          title="Battle Pass"
          link="/gfl-exilium/guides/battle-pass"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_bp.webp"
              alt="Battle Pass"
            />
          }
        />
        <CategoryCard
          title="Characters & Progression"
          link="/gfl-exilium/guides/characters-and-progression"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_progression.webp"
              alt="Characters & Progression"
            />
          }
        />
        <CategoryCard
          title="Combat Guide"
          link="/gfl-exilium/guides/combat-guide"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_combat.webp"
              alt="Combat Guide"
            />
          }
        />
        <CategoryCard
          title="Gacha explained"
          link="/gfl-exilium/guides/gacha-explained"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_gacha.webp"
              alt="Gacha explained"
            />
          }
        />
        {/* <CategoryCard
          title="Monthly Pull Income"
          link="/gfl-exilium/guides/monthly-pull-income"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_income.webp"
              alt="Monthly Pull Income"
            />
          }
          isWIP
        /> */}
        <CategoryCard
          title="Platoon System"
          link="/gfl-exilium/guides/platoon-guide"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_guild.webp"
              alt="Platoon Explained"
            />
          }
        />
        <CategoryCard
          title="Weapons & Attachments"
          link="/gfl-exilium/guides/weapons-and-attachments"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_attachment.webp"
              alt="Weapons & Attachments"
            />
          }
        />
      </Row>
      <SectionHeader title="Database" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Characters"
          link="/gfl-exilium/characters"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_characters.jpg"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Weapons"
          link="/gfl-exilium/weapons"
          image={
            <StaticImage
              src="../../../images/exilium/categories/category_weapons.webp"
              alt="Weapons"
            />
          }
        />
      </Row>
    </DashboardLayout>
  );
};

export default ExeKGuides;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Best guides for Girls' Frontline 2: Exilium that will help you understand the game better."
    game="exilium"
  />
);
